import React from 'react';

import { FiTwitter, FiLinkedin, FiInstagram} from 'react-icons/fi'

import { Container, 
         Content, 
         Products,
         LinkPolicies } from './styles';

const Summary = () => {
    return (
        <>
            <Container id="summary">
                <Content>
                    <h1>Strategi App</h1>
                    <p>Com o app o corretor é capaz de visualizar toda sua carteira de recebimento, solicitar pagamento e antecipar suas comissões.</p>

                    <div>
                        <a href="https://www.instagram.com/strategiapp" target="_blank"><FiInstagram size={24} /></a>
                        
                        <a href="https://www.linkedin.com/company/strategi-consultoria" target="_blank"><FiLinkedin size={24} /></a>

                        <a href="https://twitter.com/explore" target="_blank"><FiTwitter size={24} /></a>
                    </div>


                    <LinkPolicies to="/privacypolicy/">Política de Privacidade</LinkPolicies>

                    <LinkPolicies to="/terms/">Termo de Uso</LinkPolicies>


                </Content>

                <Products>
                    <div>
                        <h1>Soluções em BI</h1>
                        <p>Desenvolvimento de Data Intelligence</p>
                    </div>
                    
                    <div>
                        <h1>Consultoria Financeira</h1>
                        <p>Conheça melhor seu financeiro</p>
                    </div>

                    <div>
                        <h1>Desenvolvimento de Sistemas</h1>
                        <p>Sistemas de acordo com sua necessidade</p>
                    </div>
                </Products>
            </Container>
        </>
    )
}

export default Summary