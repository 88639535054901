import styled from 'styled-components'

import { Link } from "gatsby"

export const Container = styled.section`
    background: #173746;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Content = styled.div`
    flex-direction: column;

    margin: 80px auto;
    text-align: justify;
    max-width: 423px;
    
    @media (max-width: 768px) {
        margin: 60px 40px 20px;
    }

    h1 {
        font-family: "Avenir", Avenir, Montserrat;;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        color: #FFFFFF;
    }

    p {
        margin-top: 20px;
        font-family: "Avenir", Avenir, Montserrat;;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #ADB5BD;
    }


    div {
        display: flex;
        width: 136px;
        height: 24px;
        margin: 39px auto;
        justify-content: space-between;

        @media (max-width: 768px) {
            margin: 39px 0;
        }

        svg {
            color: #80F5E4;
        }
    }
`;

export const LinkPolicies = styled(Link)`
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Avenir", Avenir, Montserrat;;
    font-weight: 900;
    font-size: 12px;
    line-height: 38px;
    color: #FFFFFF;
`;

export const Products = styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    @media (max-width: 768px) {
        margin-left: 40px;
        margin-bottom: 60px;
    }
    
    div {
        display: flex;
        margin: auto auto;

        @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 0;
        }

        & + div {
            padding-top: 30px;
        }

        h1 {
            width: 150px;
            font-family: "Avenir", Avenir, Montserrat;;
            font-weight: 900;
            font-size: 18px;
            line-height: 24px;
            color: #80F5E4;
        }

        p {
            max-width: 166px;
            margin-left: 55px;

            color: #ADB5BD;
            font-family: "Avenir", Avenir, Montserrat;;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;

            @media (max-width: 768px) {
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }
`;
